<template>
  <div>
<div class="modal fade" id="QRComponentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-bind="$attrs" ref="qrCompModal">
  
  <div class="modal-dialog modal-lg">
      <div class="modal-content " id="qrComp" >
       

          <!-- Modal Content -->
          <div >
            <!-- Loader -->
            <div v-if="isQrLoading">
              <LoaderComp />
            </div>

            <div v-else class="" >
              <div class="container p-4" >
                  <div class="w-100 text-right">
                  <button 
                  @click="CloseModal"
          class="btn"
          style="cusrsor: pointer;color:#4d4950;font-size:20px;"><i class="fa fa-times" aria-hidden="true"></i></button>
                  
                 
              </div>
                 <div class="row  px-3  ">
              <div>
                  <h4 class="font-weight-bold" style="color:#18191C">Here is your quiz’s QR code</h4>
              </div>
              
          </div>

          <div class="row">
              <div class="col-12 ">
                  <p style="color:#4d4950;font-weight:400;font-size:13px;">We made it easy for you and generated a unique QR for your quiz. Download  the QR code and launch the quiz.</p>
              </div>
              <div class="col-12">
                  <div
                                class=" w-100 d-flex justify-content-center"
                            >
                              
                              <!-- <qr-code 
                              id="QRCodeGenerate"
                              ref="QRRef"
    :text="URL"
    :size="300"
    error-level="L">
</qr-code> -->

<VueQRCodeComponent  id="QRCodeGenerate"
                              ref="QRRef"
    :text="URL"
    :size="300"
    error-level="L" />
                                
                            </div>
              </div>

              <div class="col-12 mt-3 text-right">
                 
                  <button @click="downloadQR" class="btn px-3 py-2 mx-3" style="background:#4d4950;border-radius:6px;color:#ffffff;">Download QR </button>
              </div>
          </div>

          <div class="row">
          </div>

          
              </div>
            </div>
          </div>
        
      </div>
    </div>
</div>
  </div>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'
import LoaderComp from "../../customize/component/LoaderComp.vue";
import $ from 'jquery'
export default {
    components:  {
        LoaderComp,
        VueQRCodeComponent
    },
    data(){
        return{
            isQrLoading:false,
            URL:''
        }
    },
    computed:{
      
    },
methods:{
OpenModal(url){
    this.URL = url
        $('#QRComponentModal').modal('show')

    },
    CloseModal(){
         $('#QRComponentModal').modal('hide')
    },
    downloadQR(){
        let parent = document.getElementById('QRCodeGenerate');
let childs = parent.childNodes;
const base64  = childs[1].src

        if(base64){
            var a = document.createElement("a"); 
    a.href =  base64; 
    a.download = "QR.png";
    a.click(); 
        }

        this.CloseModal()
    }

    
},
mounted() {
        // $(this.$refs.qrCompModal).on("show.bs.modal", this.getEmbedCode)
    },
}
</script>

<style >
#qrComp{
    border-radius:20px;
}
.QuestionsDiv{
    height:100%;
}

[contenteditable] {
  outline: 0px solid transparent;
}

</style>